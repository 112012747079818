import React, { useState } from "react";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import Matrix from "../components/matrix";
import Layout from "../components/layout";
import EditLink from "../components/newComponents/editLink";
import GeneralSection from "../components/newComponents/generalSection";
import Card from "../components/newComponents/card";
import HomePageSlider from "../components/HomePageSlider";
import ImgixFluidImage from "../components/newComponents/ImgixFluidImage";

export const query = graphql`
  query PageEntryQuery($slug: String!, $id: ID!) {
    entry: craftPagePageEntry(slug: { eq: $slug }) {
      displayTitle
      id
      remoteId
      sectionHandle
      featureImage {
        ... AssetFragment
      }
      showFeatureImage
      title
      postDate
      customNavLogo {
        ...AssetFragment
      }
      carousel {
        ... on Craft_carousel_slide_BlockType {
          id
          navText
          linkText
          linkText2
          subhead
          head
          customUrl
          customUrl2
          image {
            ...AssetFragment
          }
          relatedEntry {
            id
            uri
          }
          relatedEntry2 {
            id
            uri
          }
        }
      }
      bodyContent {
        ...BodyContentFragment
      }
      featureImage {
        url
      }
    }

    relatedFeedNews: allCraftNewsNewsEntry(filter: {relatedPage: {elemMatch: {remoteId: {eq: $id}}}}) {
      nodes {
        sectionHandle
        displayTitle
        slug
        uri
        postDate
        summary
        featureImage {
          ...AssetFragment
        }
      }
    }

    relatedFeedNewsletters: allCraftNewslettersNewslettersEntry(filter: {relatedPage: {elemMatch: {remoteId: {eq: $id}}}}) {
      nodes {
        sectionHandle
        uri
        displayTitle
        summary
        postDate
        newsletterLink
        downloads {
          url
          size
          kind
        }
        featureImage {
          ...AssetFragment
        }
      }
    }

    relatedFeedReportsAndOtherDocuments: allCraftResourcesResourcesEntry(filter: {relatedPage: {elemMatch: {remoteId: {eq: $id}}}}) {
      nodes {
        sectionHandle
        displayTitle
        uri
        postDate
        summary
        featureImage {
        ...AssetFragment
        }
      }
    }
  }
`;

const PageEntry = ({ data: { entry, relatedFeedNews, relatedFeedNewsletters, relatedFeedReportsAndOtherDocuments }, pageContext, }) => {

  const [shownItems, setShownItems] = useState(9);

  const feedItems = relatedFeedNews.nodes.concat(relatedFeedNewsletters.nodes.concat(relatedFeedReportsAndOtherDocuments.nodes));  

  feedItems.sort(function(a, b) {
      return new Date(b.postDate).getTime() - new Date(a.postDate).getTime();
  });

  const finalImage = entry.featureImage[0] && entry.showFeatureImage ? <ImgixFluidImage attribution={entry.featureImage[0].attribution} imageUrl={entry.featureImage[0].url} sizes="100vw" ar={1100/400} focalPoint={entry.featureImage[0].focalPoint}/>  : null;

  return (
    <Layout pageContext={pageContext} customNavLogo={entry.customNavLogo[0]} customClassName={`main page-${pageContext.slug}`}>
      {entry.carousel && entry.carousel.length > 0 && <HomePageSlider data={entry.carousel} />} 
      <Seo title={entry.displayTitle} image={entry.featureImage[0]?.url} />
      <div>
        <GeneralSection className="section-inside" style={{paddingBottom: 0}}>
          <header className="section__head">
            {finalImage ? <figure className="section__head-image">{finalImage}</figure> : <></>}
            <h1 className="section__title title h2 text-gray">{entry.title}</h1>
          </header>
        </GeneralSection>
        <Matrix blocks={entry.bodyContent} />
        {feedItems.length > 0 ?
          <GeneralSection className="section-listing js-section-listing">
            <header className="section__head">
              <p className="section__title title h2 text-gray">News and insights</p>
            </header>
            <div className="boxes swiper-container js-load-more js-slider-boxes-projects">
            <ul> 
              {feedItems.map((item, i) => (
                i < shownItems ?
                  <Card key={`related-feed-${i}`} image={item.featureImage[0]} ar={2} title={item.displayTitle} subtitle={item.summary} href={`/${item.uri}`}/>
                  :
                  <></>
              ))}
            </ul>
            <div className="swiper-pagination boxes__pagination" />
          </div>
          {shownItems < feedItems.length ? 
            <div className="flex justify-center">
              <button type="button" onClick={() => {setShownItems(shownItems + 9)}} className="btn btn--green btn--icon form__btn">Load more</button>
            </div>
            :
            <></>
          }
          </GeneralSection>
          :
          <></>
        }
        <GeneralSection>
          <EditLink sectionHandle={entry.sectionHandle} remoteId={entry.remoteId}/>
        </GeneralSection>
      </div>
    </Layout>
  );
};

export default PageEntry;